.sectoconf-wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
}

.config-container {
  height: 64px;
  margin: 0 5px 0 16px;
  padding: 8px 6px 8px 6px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: solid 1px var(--Blue2);
  border-radius: 2px;
  background-color: var(--Blue3Bis);
  align-items: center;
}

.config-expand {
  width: 430px;
  height: 102px;
  border-radius: 2px;
  border: solid 1px var(--White);
}

.config-container .buttons {
  margin: 8px auto;
  grid-column-start: 5;
}

.config-container .input-values {
  padding: 0px 8px;
  white-space: nowrap;
}

.config-container input[type="text"],
.config-container select {
  width: 48px;
  height: 32px;
  box-shadow: 1 2px 4px 0;
  background-color: rgba(0, 0, 0, 0.25);
  margin: 5px auto;
  text-align: center;
}

.config-container input.invalid {
  border: 1px solid red !important;
}

.config-container select {
  width: 89px;
  height: 32px;
}

.config-select .config-select__single-value {
  color: var(--White);
  font-weight: normal;
}

.config-select .config-select__control,
.config-select .config-select__input,
.config-select .config-select__menu,
.config-select .config-select__indicator,
.config-select
  .config-select__value-container
  div:not(.config-select__single-value) {
  margin: 0px;
  padding: 0px;
}
.config-select .config-select__menu {
  z-index: 10;
}

.config-select .config-select__control {
  min-height: 28px;
  background-color: rgba(0, 0, 0, 0.25);
  border: none !important;
  color: var(--White);
  margin: 5px auto;
}

.config-select .config-select__input {
  color: var(--White);
  height: 24px;
}

.config-select .config-select__input input {
  height: 24px;
  margin: 5px 0px 5px 0px;
}

.config-select .config-select__menu-list {
  background-color: #333333;
  color: var(--White);
}

.config-select .config-select__option--is-focused {
  color: var(--Black);
}

.config-select.config-select--is-disabled .config-select__indicators {
  display: none;
}

.config-container label {
  padding: 0px 4px;
  margin-bottom: 0px;
}

.delete-edit {
  align-self: center;
  justify-self: right;
}

.editing-config {
  height: 102px;
  border: solid 1px var(--White);
}

.past-config {
  background-color: var(--Blue3);
  pointer-events: none;
}

.past-config input[type="text"],
.past-config .config-select__single-value {
  color: var(--GreyDisable);
}

.future-config:hover {
  background-color: var(--Blue4);
}

.current-config:hover {
  border: solid 2px var(--Bluewriting);
}

.clicked-config {
  background-color: var(--Blue);
}

.input-values span {
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
}

.input-values input[type="text"] {
  border-style: hidden;
  font-weight: 400;
}

.input-values > .input-past {
  color: var(--GreyDisable);
}

.current-declaration-status {
  width: 37px;
  height: 13px;
  font-size: 10px;
  font-weight: 500;
  align-self: center;
  justify-self: center;
  color: var(--White);
}

.oval {
  width: 9px;
  height: 9px;
  border-radius: 6px;
  align-self: center;
  background-color: var(--White);
  margin-left: 5px;
}

.active-grid {
  display: grid;
  grid-template-columns: repeat(2, 0.5fr);
  margin-left: 10px;
}

.options-buttons {
  visibility: hidden;
  display: flex;
  justify-content: flex-end;
}

.options-buttons > .edit-delete-buttons {
  width: 50%;
  display: flex;
  justify-content: center;
}

.config-container:hover .options-buttons {
  visibility: visible;
}

.options-buttons button {
  padding-top: 5px;
}

.options-buttons button:hover {
  opacity: 0.4;
  border-radius: 2px;
  background-color: var(--Black);
}

.active-align {
  align-self: center;
}

.validation {
  margin: -6px 5px 2px 16px;
}

.validation-message-bar {
  width: 100%;
  background-color: var(--White);
  height: 50px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.validation-message-color {
  font-size: 12px;
  padding-top: 10px;
  font-weight: bold !important;
  text-align: center;
  color: red;
}

.single-validation-message-pos {
  padding-top: 18px;
}

.cancel-button {
  background: transparent;
  border: none !important;
  cursor: pointer;
  margin-left: 40px;
  grid-row-start: 2;
  grid-column-start: 4;
}

.ok-button {
  width: 64px;
  height: 24px;
  color: var(--Blue);
  background-color: var(--White);
  font-weight: 500 !important;
  grid-column-start: 5;
  margin: 0 14px !important;
}
