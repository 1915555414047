.insertConfiguration {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
}

.insertConfigurationButton {
  display: none;
}

/* height of the effect to insert row, 
the height should be twice the number of the margin pixels you want on both sides of the separation */
.hover-area {
  z-index: 1;
  height: 20px;
  margin: -10px;
}
/* height of the hover area when the button is visible, 
its value is the sum of 2 heights:  hover-area + appearing element 
 */
.hover-area:hover {
  height: 44px;
}

/* last child of the page doesnt need to extend the hover-area on another element
so we need to remove the value of the unnecessary overflow hover the non existing element */
.hover-area:hover:last-child {
  height: 34px;
  align-items: end;
}

.hover-area {
  position: relative;
  margin-right: 5px;
  margin-left: 16px;
}

.hover-area:hover {
  display: flex;
  align-items: center;
}

.insertConfiguration .insertConfigurationButton {
  margin: 0px auto;
  padding: 3px 7px;
  width: max-content;
  height: 20px;
  background-color: #37469c;
  border-radius: 2px;
  display: flex;
  text-wrap: nowrap;
  border: none;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  z-index: 2;
}

.insertConfiguration .line {
  height: 2px;
  background-color: #37469c;
  z-index: 1;
  position: relative;
  width: 100%;
}

.insertConfigurationButton > span {
  margin: 0;
  text-align: center;
}
